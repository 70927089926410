<template>
  <div
    class='flex flex-col lg:flex-row p-8 justify-between items-start lg:items-stretch w-full'
  >
    <div class='w-full flex flex-col lg:flex-row items-start lg:items-top'>
      <div class='px-3'>
        <label
          for='filters'
          class='block text-sm font-medium text-gray-700'
        >
          {{ $t('admin.search_by_name') }}
        </label>
        <div class='mt-1 flex rounded-md shadow-sm'>
          <button @click.prevent='onFilterChange(filterSearch)'
                  class='inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm'
          >
            <IconSearch class='stroke-current text-blue-600 h-4' />
          </button>
          <input
            type='text'
            v-model='filterSearch'
            id='filters'
            name='filters'
            autocomplete='filters'
            class='flex-1 focus:ring-blue-500 focus:border-blue-500 block w-full min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300'
          />
        </div>
      </div>
    </div>
    <router-link to='/company/user/create'>
      <button type='button'
              class='inline-flex items-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
      >
        {{ $t('companies.user.create_one') }}
      </button>
    </router-link>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  name: 'UsersFilters',
  components: {},
  data() {
    return {
      filterSearch: '',
      filters: {}
    }
  },
  computed: {
    ...mapState('company', {
      config: (state) => state.usersConfig
    })
  },
  methods: {
    ...mapActions('company', ['getUsers']),
    onFilterChange(currentFilter) {
      this.config.page = 1
      this.filters.name = currentFilter
      this.config.filters = this.filters
      this.$emit('on-filter-change')
    }
  }
}
</script>

<style></style>
