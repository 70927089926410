<template>
  <main v-if='isLoading' id="js-page-content" class="page-content" role="main">
    <div class="moverflow-x-auto">
      <div
        class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg bg-white"
      >
        <TabUsers v-if="users" @get-data='getData'></TabUsers>
      </div>
    </div>
  </main>
</template>

<script>
import TabUsers from '@/components/Model/Company/TabUsers'
import { mapActions, mapState } from 'vuex'
export default {
  name: 'Users',
  components: {
    TabUsers,
  },
  data() {
    return {
      isLoading : false
    }
  },
  created() {
    this.getData();
  },
  computed: {
    ...mapState('company', {
      users: (state) => state.users,
    }),
  },
  methods: {
    ...mapActions('company', ['getUsers']),
    getData(){
      this.getUsers().then(
        () => {
          this.isLoading= true
        }
      ).catch(
        (e) => {
          this.$store.dispatch('dispatchError', e)
        }
      )
}
  },
}
</script>
