<template>
  <div
    class="modal fade"
    :id="'modalDelete' + item.id"
    tabindex="-1"
    role="dialog"
    style="display: none; padding-right: 16px"
    aria-modal="true"
  >
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 v-if="item.action == 'Supprimer'" class="modal-title">
            Voulez-vous supprimer {{ item.object }} {{ item.name }}
          </h5>
          <h5 v-if="item.action == 'Sauvegarder'" class="modal-title">
            Voulez-vous modifier {{ item.object }} {{ item.name }}
          </h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true"><i class="fal fa-times"></i></span>
          </button>

        </div>
        <div class="modal-body text-justify">
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary waves-effect waves-themed"
            data-dismiss="modal"
          >
            Annuler
          </button>
          <button
            v-if="item.action == 'Supprimer'"
            @click="del()"
            type="button"
            class="btn btn-danger waves-effect waves-themed"
            data-dismiss="modal"
          >
            {{ item.action }}
          </button>
          <button
            v-if="item.action == 'Sauvegarder'"
            @click="save()"
            type="button"
            data-dismiss="modal"
          >
            {{ item.action }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "ModalDeleteSave",
  props: ["item"],
  data: function () {
    return {};
  },
  created() {},
  methods: {
    del: function () {
      axios.delete(this.item.deleteLink).then((response) => {
        $("#modalDelete" + this.item.id).hide();
        this.$router.go(0);
      });
    },
    save: function () {
      this.del()
    },
  },
};
</script>
<style>
.modal-backdrop {
    z-index: -1;
}
</style>